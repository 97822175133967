import settings from '@/router/settings'
import users from '@/router/users'
import recordDelivery from '@/router/record-delivery'
import printers from '@/router/printers'
import copyEditor from '@/router/copy-editor'
import enforcement from '@/router/enforcement'
import brands from '@/router/brands'
import inventoryLocation from '@/router/inventory-location'
import packaging from '@/router/packaging'
import outbound from '@/router/outbound'
import products from '@/router/products'
import forecast from '@/router/forecast'
import netsuiteReceipts from '@/router/netsuite-receipts'
import reportUploader from '@/router/report-uploader'

const modules = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Dashboard',
    },
  },
  {
    path: '/sales/forecast/daily',
    name: 'daily-sales-forecast',
    component: () => import('@/views/forecast/sales/ForecastDaily.vue'),
    meta: {
      pageTitle: 'Daily Sales Forecast',
      breadcrumb: [
        {
          text: 'Daily Sales Forecast',
          active: true,
        },
      ],
      action: 'read',
      resource: 'SaleForecastDaily',
    },
  },
  {
    path: '/profile',
    name: 'users.profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Users',
          to: { name: 'users.list' },
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
      action: 'manage',
      resource: 'all',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/error-403',
    name: 'not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('@/views/success-pages/ThankYou.vue'),
    meta: {
      action: 'read',
      resource: 'Auth',
      layout: 'full',
    },
  },
  ...settings,
  ...users,
  ...recordDelivery,
  ...printers,
  ...copyEditor,
  ...enforcement,
  ...brands,
  ...inventoryLocation,
  ...packaging,
  ...outbound,
  ...products,
  ...forecast,
  ...netsuiteReceipts,
  ...reportUploader,
  {
    path: '*',
    redirect: 'error-404',
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },
]

export default modules
