import Vue from 'vue'
import { ToastPlugin, ModalPlugin, PopoverPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import apolloClient from '@/libs/apollo'
import VueApollo from 'vue-apollo'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import PrimeVue from 'primevue/config'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import PortalVue from 'portal-vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'
import VueSweetalert2 from 'vue-sweetalert2'

import { abilitiesPlugin } from '@casl/vue'
import AuthMixin from './mixins/auth'
import App from './App.vue'
import store from './store'
import router from './router'
import ability from './libs/acl/ability'

Vue.use(VueCompositionAPI)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(VueSweetalert2)
Vue.use(abilitiesPlugin, ability)

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

// const primeVue = new PrimeVue({
//   unstyled: true,
// })
Vue.use(PortalVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(PopoverPlugin)
Vue.use(VueApollo)
Vue.use(PrimeVue)

// Composition API
// Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.mixin(AuthMixin)

Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Sentry.init({
  Vue,
  enabled: !!process.env.VUE_APP_SENTRY_DSN,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', process.env.VUE_APP_DOMAIN, /^\//],
    }),
  ],
  logErrors: true,
  tracesSampleRate: 0.5,
  beforeSend(event, hint) {
    if (hint?.originalException?.networkError?.response?.data?.message === 'Please provide token' && hint?.originalException?.networkError?.response?.data?.statusCode === 400) {
      return null
    }
    return event
  },
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
